import React from 'react';

import MuiField from '../MuiFieldWhite'
import { errorCss } from './FormikField.styles';

const FormikField = ({ field, form, style, ...rest }) => {
  const { errors, touched } = form;
  const { name } = field;

  return (
    <div style={{ position: 'relative', ...style }}>
      <MuiField {...field} {...rest} />
      {errors[name] && touched[name] && (
        <div css={errorCss}>{errors[name]}</div>
      )}
    </div>
  );
}

export default FormikField;
