import React, { useState, useEffect, useMemo } from 'react'
import { Field, withFormik } from 'formik'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import Error from '@material-ui/icons/Error'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Button } from '../components/styles/ContainedButton.styles'
import MuiSelect from '../components/Dropdown'
import Modal from './Modal'
import H6 from './H6'
import FormikField from '../components/FormikFieldWhite'
import FormikCheckbox from './FormikCheckbox/FormikCheckbox'

import RecaptchaKey from '../components/RecaptchaKey'

const initialModals = {
  support: false,
  moreInfo: false,
  success: false,
  error: false
}

const Loader = withStyles({
  root: {
    color: '#FFFFFF'
  }
})(CircularProgress)

const SuccessModal = ({ visible, onClose }) => {
  return (
    <Modal open={visible} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 30
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: '#7ed321',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Check
            style={{
              color: 'white',
              flexShrink: 0,
              fontSize: 36
            }}
          />
        </div>
      </div>
      <H6
        selected
        on='onSurface'
        variant='highEmphasis'
        align='center'
        mb={[5]}
      >
        Agradecemos seu<br />
        contato! Retornaremos<br />
        em breve.
      </H6>
    </Modal>
  )
}

const ErrorModal = ({ visible, onClose }) => {
  return (
    <Modal open={visible} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 30
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Error
            style={{
              color: '#b00020',
              flexShrink: 0,
              fontSize: 50
            }}
          />
        </div>
      </div>
      <H6
        selected
        on='onSurface'
        variant='highEmphasis'
        align='center'
        mb={[5]}
      >
        Desculpe-nos,<br /> 
        não foi possível<br /> 
        enviar o formulário.<br />
        Tente novamente.
      </H6>
    </Modal>
  )
}

const FormikSelect = ({ field, form, options, label, ...rest }) => {
  return (
    <MuiSelect
      options={options}
      label={label}
      value={options.find(option => option.value === field.value)}
      onChange={value => {
        form.setFieldValue(field.name, value.value)
      }}
      isClearable={false}
      {...rest}
    />
  )
}

const validate = value => {
  let error;

  if (!value) {
    error = 'Campo Obrigatório';
  }

  return error;
};

const ContactForm = ({
  fields,
  values,
  isSubmitting,
  isValid,
  errors,
  submitForm,
  submitText,
  status,
  setFieldValue,
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }

  useEffect(() => {
    if (status === 'submitted') {
      setModalVisibility('success')(true)
    }

    if (status === 'error') {
      setModalVisibility('error')(true)
    }

    return () => {
      setModalVisibility('success')(false)
      setModalVisibility('error')(false)
    }
  }, [status])

  const handleRecaptchaToken = (token) => {
    setFieldValue('_wpcf7_recaptcha_response', token);
  };

  const formFields = useMemo(() => {
    return fields.map((field, i) => {
      if (typeof field.render === 'function') {
        return field.render();
      }

      if (field.type === 'checkbox') {
        return (
          <Field
            key={`contact-field-${i}`}
            label={field.label}
            name={field.name}
            component={FormikCheckbox}
            validate={field.validate || field.required && validate}
            style={{
              width: 328,
              marginBottom: 24,
              textAlign: 'left'
            }}
          />
        )
      }

      if (field.type === 'select') {
        return (
          <Field
            key={`contact-field-${i}`}
            label={field.label}
            name={field.name}
            placeholder={field.label}
            component={FormikSelect}
            options={field.options}
            validate={field.validate || field.required && validate}
            multiline={field.multiline}
            rows={field.rows}
            mask={field.mask}
            style={{
              width: 328,
              marginBottom: 24,
              textAlign: 'left'
            }}
          />
        )
      }

      return (
        <Field
          key={`contact-field-${i}`}
          variant='outlined'
          label={field.label}
          name={field.name}
          placeholder={field.label}
          component={FormikField}
          validate={field.validate || field.required && validate}
          multiline={field.multiline}
          rows={field.rows}
          mask={field.mask}
          style={{
            maxWidth: 328,
            marginBottom: 24
          }}
        />
      );
    });
  }, [fields]);

  return (
    <>
      {formFields}
      <Field
        name='_wpcf7_recaptcha_response'
        validate={validate}
        type='hidden'
      />
      <RecaptchaKey onVerifyToken={handleRecaptchaToken} />
      <Button
        disabled={isSubmitting || !isValid}
        onClick={submitForm}
        style={{
          marginTop: 14,
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 328
        }}
      >
        {!isSubmitting ? submitText : <Loader size={14} />}
      </Button>
      <SuccessModal
        visible={modals.success}
        onClose={() => setModalVisibility('success')(false)}
      />
      <ErrorModal
        visible={modals.error}
        onClose={() => setModalVisibility('error')(false)}
      />
    </>
  )
}

ContactForm.defaultProps = {
  submitText: 'Enviar',
}

export default withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: async (values, { setSubmitting, setStatus, props }) => {
    setStatus('unsubmitted');

    const { terms, ...restValues } = values;

    try {
      const formData = new FormData();
      Object.keys(restValues).forEach(key => {
        formData.set(key, restValues[key])
      });

      const response = await axios
        .post(`https://hostdime.com.br/wp/wp-json/contact-form-7/v1/contact-forms/${props.formId}/feedback`, formData)

      console.log(response.data)
      if (response.data.status === 'validation_failed') {
        throw new Error('Formulário inválido.')
      }

      await new Promise(resolve => setTimeout(() => resolve(), 2000)) // TODO REMOVE

      setStatus('submitted')
      setSubmitting(false)
    } catch (e) {
      console.error(e)
      setStatus('error')
      setSubmitting(false)
    }
  }
})(ContactForm)
