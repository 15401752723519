import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Img from "gatsby-image"
import { graphql, Link }  from 'gatsby'
import compose from 'lodash/fp/flowRight'
import UnderstandTheDifference from '../components/UnderstandTheDifference'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import Hr from '../components/Hr'
import H3 from '../components/H3'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Body2 from './../components/Body2'
import Seguranca from '../images/storage/inspecao@4x.png'
import CustoBeneficio from '../images/storage/tempo_real@4x.png'
import PorqueUsar from '../images/storage/por_que_usar@4x.png'
import ArrowR from '@material-ui/icons/ArrowForward'
import Accordion from '../components/Accordion'
import { btnSmall } from '../components/styles/ContainedButton.styles'

import { Container } from '../components/styles/Layout.styles'
import { withSolutions } from '../components/SolutionProvider'

import { faqCloudServer, listStorage } from '../components/styles/ProductCloud.styles'
import { titleStyle, titleForm } from '../components/styles/InternalPage.styles'
import { 
  termosBoxWhite 
} from '../components/styles/Contact.styles'
import StorageForm from '../components/FormWhiteItens'
import { validateRequiredEmail } from '../utils/validations'
const fields = [
  {
    label: 'Nome',
    name: 'your-name', 
    type: 'text',
    required: true
  },
  {
    label: 'Telefone',
    name: 'your-phone',
    type: 'text',
    required: true,
    mask: ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  {
    label: 'Email',
    name: 'your-email',
    type: 'email',
    required: true,
    validate: validateRequiredEmail,
  },
  {
    label: 'Empresa',
    name: 'your-company',
    type: 'text',
    required: true
  },
  {
    label: 'Cargo',
    name: 'your-position',
    type: 'text',
    required: true
  },
  {
    label: (
      <span css={termosBoxWhite}>
        Ao continuar, indica que aceita os
        {" "}
        <Link to="/termos-de-servico" style={{ textDecoration: 'underline' }}>
          Termos de Serviço
        </Link>
        {" "}
        e a nossa
        {" "}
        <Link to="/politica-de-privacidade" style={{ textDecoration: 'underline' }}>
          Política de Privacidade.
        </Link>
      </span>
    ),
    name: 'your-consent',
    type: 'checkbox',
    required: true
  },
]

const initialModals = {
  support: false,
  moreInfo: false
}

export const PageTemplate = ({
  data,
  ...rest
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <Fragment>
      <section>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'row']}
              mt={[50]}
              mb={[50]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                display={['none', 'none', 'flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                >
                  <H4
                    style={{
                      fontSize: 11.8,
                      color: 'rgba(0, 0, 0, 0.6)',
                      fontWeight: 600,
                      lineHeight: 1.36,
                      letterSpacing: 2,
                      marginBottom: 8
                    }}
                  >
                    STORAGE HOSTDIME
                  </H4>
                  <H3
                    color='black'
                    variant='highEmphasis'
                    mb={[3, 5, 3]}
                    css={titleStyle}
                    maxWidth={['90%', 328, 416, 416]}
                  >
                    Armazenamento seguro em bloco ou objeto
                  </H3>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    // order={[2, 2, 1]}
                    mb={[4]}
                    maxWidth={['90%', 422, 416, 500]}
                  >
                    Conheça a solução de armazenamento mais segura, escalável e altamente disponível que evolui com as necessidades da sua empresa. Em blocos SSD/NVMe, HDD ou objeto.
                  </Body2>
                  <ScrollAnim.Link
                    to='consulte'
                    offsetTop='120'
                    css={btnSmall}
                  >
                    <div>
                      CONSULTE
                    </div>
                  </ScrollAnim.Link>
                  <Div
                    maxWidth={['70%', 328, 416, 416]}
                  >
                    <H5
                      color='black'
                      variant='highEmphasis'
                      mt={[4]}
                    >
                      Armazenamento em objeto compatível com S3 e Swift.
                    </H5>
                  </Div>
                  <Div
                    display={['block', 'block', 'none']}
                    mt={[8, 8, 0]}
                    maxWidth={[310, 310, 327, 450]}
                  >
                    <Img
                      fluid={data.image1.childImageSharp.fluid}
                      loading='eager'
                    />
                  </Div>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
        <Div 
          alignItems={['center']}
        >
          <Hr
            lineColor='lightPeriwinkle'
            m={['0']}
            maxWidth={[328, '100%' ,680 , 1024]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
      </section>
      <section>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[40, 50]}
            justifyContent={['left', 'center']}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <H4
              color='black'
              variant='highEmphasis'
              css={titleForm}
              maxWidth={['90%', 400, 510, 510]}
              style={{
                textAlign: 'center'
              }}
            >
              Storage com desempenho 10x mais veloz
            </H4>
          </Row>
          <Row
            mt={30}
            mb={70}
          >
            <Div 
              flexDirection={['column', 'column', 'unset', 'unset']}
              justifyContent={['center']}
              alignItems={['center']}
              style={{
                margin: '0 auto'
              }}
            >
              <Column
                mb={[8, 8,  8,  0]}
                mr={[0 , 0, 144]}
              >
                <Div
                  maxWidth={['100%', 400]}
                  alignItems={['center']}
                >
                  <img 
                    src={Seguranca} 
                    style={{
                      width: 100
                    }}
                  />
                  <H6
                    color='black'
                    variant='blackMediumEmphasis'
                    mb={[3]}
                    maxWidth={['90%', 400]}
                    style={{
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.6)'
                    }}
                  >
                    MAIS SEGURANÇA E DISPONIBILIDADE
                  </H6>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    maxWidth={['90%', 400]}
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    Com o Storage  HostDime, o seu armazenamento é espelhado em mais de um disco, interligados na plataforma e monitorados 24h por dia. Manutenções, expansões e trocas de discos são feitas sem a necessidade de desligar a máquina. 
                  </Body2>
                </Div>
              </Column>
              <Column
                mt={[50, 0]}
                mb={[8, 8, 8,  0]}
              >
                <Div
                  maxWidth={['100%', 400]}
                  alignItems={['center']}
                >
                  <img 
                    src={CustoBeneficio} 
                    style={{
                      width: 100
                    }}
                  />
                  <H6
                    color='black'
                    variant='blackMediumEmphasis'
                    mb={[3]}
                    maxWidth={['90%', 400]}
                    style={{
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.6)'
                    }}
                  >
                    MELHOR CUSTO-BENEFÍCIO
                  </H6>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    maxWidth={['90%', 400]}
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    O Storage HostDime possui escalabilidade para ajustes a curto prazo, conforme suas necessidades mudam. Além disso, você paga apenas pelo que usar e em real, sem variação do dólar!
                  </Body2>
                </Div>
              </Column>
            </Div>
          </Row>
        </Container>
        <Div 
          alignItems={['center']}
        >
          <Hr
            lineColor='lightPeriwinkle'
            m={['0']}
            maxWidth={[328, '100%' ,680 , 1024]}
            style={{
              opacity: 0.5
            }}
          />
        </Div>
      </section>
      <section style={{ backgroundColor: '#9013fe' }} id='consulte'>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'column', 'row']}
              mt={[50, 100]}
              mb={[50, 100]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center', 'end']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                mb={[26, 0]}
                display={['flex']}
                style={{
                  justifyContent: 'center'
                }}
              >
                <Div
                  maxWidth={['100%', '100%', 400, 450]}
                  alignItems={['center']}
                >
                  <img 
                    src={PorqueUsar} 
                    style={{
                      width: 120
                    }}
                  />
                  <H4
                    color='white'
                    variant='highEmphasis'
                    mt={[3]}
                    mb={[5]}
                    css={titleForm}
                    maxWidth={['90%', 422, 380, 380]}
                    style={{
                      textAlign: 'center'
                    }}
                  >
                    Por que usar o Storage HostDime?
                  </H4>
                  <Div
                    maxWidth={['82%', 410, 410, 410]}
                  >
                    <div css={listStorage}>
                      <ArrowR 
                        style={{
                          color: '#fff',
                          marginRight: 16
                        }}
                      />
                      <span>Ajuste, otimize e controle sua estrutura a qualquer momento</span>
                    </div>
                    <div css={listStorage}>
                      <ArrowR 
                        style={{
                          color: '#fff',
                          marginRight: 16
                        }}
                      />
                      <span>Gerenciamento proativo 24 horas por dia, 7 dias por semana, 365 dias por ano</span>
                    </div>
                    <div css={listStorage}>
                      <ArrowR 
                        style={{
                          color: '#fff',
                          marginRight: 16
                        }}
                      />
                      <span>Escalabilidade sem limites</span>
                    </div>
                  </Div>
                </Div>
              </Column>
              <Column
                width={[1, 1, 5/6, 1/2]}
                mt={[0, 26, 90]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                  maxWidth={['100%', 422, 400, 400]}
                >
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    order={[2, 2, 1]}
                    maxWidth={['100%', 422, 416, 416]}
                    alignItems={['center', 'center', 'normal', 'normal']}
                  >
                    <Div
                      marginTop={90}
                      style={{
                        textAlign: 'center'
                      }}
                    >
                      <StorageForm
                        fields={fields}
                        formId={4361}
                        submitText='ENVIAR'
                      />
                    </Div>
                  </Body2>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      {false && <section css={faqCloudServer}>
        <Container>
          <Row
            mt={[40, 70]}
            mb={[50]}
            justifyContent='center'
          >
            <H4>Tire suas Dúvidas</H4>
          </Row>
          <Row
            mb={[40, 70]}
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
          >
            <Column
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', 422, 422]}
              >
                <Accordion 
                  title='Por que eu tenho que fazer um depósito para obter uma conta do Cloud Server HostDime?'
                  content='O serviço de Cloud da HostDime utiliza um sistema de dedução de crédito. Dessa forma, você precisa adicionar créditos ao sistema para fazer utilização do serviço. Você pode adicionar a partir de R$ 75,00 (valor mínimo). Uma vez que a cobrança é efetuada por hora, o sistema registrará sua utilização por hora e deduzirá esse valor de seus créditos. '
                />
                <Accordion 
                  title='Preciso monitorar constantemente meu saldo de crédito e recarregar manualmente minha conta?'
                  content='Você pode configurar em seu painel do cliente para que o sistema emita um alerta quando o saldo estiver abaixo do limite definido. Dessa forma, uma fatura de recarga será gerada automaticamente. '
                />
                <Accordion 
                  title='Que tipos de pagamentos vocês aceitam?'
                  content='São aceitos os métodos de pagamento definidos nos termos de serviço: cartão de crédito, PayPal, boleto bancário e Bitcoin.'
                />     
              </Div>
            </Column>
            <Column
              mt={[8, 8, 0, 0]}
              mb={[8, 8,  8,  0]}
            >
              <Div
                maxWidth={['100%', 422, 422]}
              >
                <Accordion 
                  title='O crédito da HostDime é diferente do crédito da HostDime Cloud?'
                  content='Sim, os créditos em nuvem só podem ser usados para o(s) produto(s) da nuvem, O crédito do HostDime Client pode ser usado para comprar crédito da HostDime Cloud.'
                />
                <Accordion 
                  title='Posso converter o crédito do HostDime em crédito no HostDime Cloud?'
                  content='Você pode gerar uma fatura para o crédito da HostDime Cloud e usar o crédito para pagar essa fatura. Não há nenhum botão de “converter” atualmente.'
                /> 
                <Accordion 
                  title='Como é faturada a utilização de banda nas instâncias?'
                  content='No DC Orlando, toda nova instância recebe gratuitamente 10TB de banda. No DC Brasil, novas instâncias recebem gratuitamente 10 TB de tráfego outbound e 5 TB de tráfego inbound.'
                />        
              </Div>
            </Column>
          </Row>
        </Container>
      </section>}
      <section>
        <Div
          px={[16, 16, 24, 24]}
          alignItems={['center']}
        >
          <Div
            maxWidth={['100%', 390, 855, 1086]}
          >
            <Row
              flexDirection={['column', 'column', 'row']}
              mt={[40, 70]}
              mb={[50, 70]}
              mx={[-16, -16, 0, 0]}
              alignItems={['center']}
              justifyContent={['center']}
            >
              <Column
                width={[1, 1, 1, 1/2]}
                maxWidth={['100%', 264]}
                style={{
                  justifyContent: 'flex-end',
                  margin: '0 auto'
                }}
              >
                <Div
                  maxWidth={[264]}
                  marginRight={[0, 26]}
                  style={{
                    margin: '0 auto'
                  }}
                >
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
              </Column>
              <Column
                width={[1, 1, 1, 1/2]}
              >
                <Div
                  alignItems={['center', 'center', 'flex-start']}
                  textAlign={['center', 'center', 'left']}
                >
                  <H5
                    color='black'
                    variant='highEmphasis'
                    mt={[4, 4, 0, 0]}
                    maxWidth={[328, 400]}
                  >
                    Fale com um de nossos consultores de negócios e entenda porque o Storage HostDime é o que você precisa. 
                  </H5>
                </Div>
              </Column>
            </Row>
          </Div>
        </Div>
      </section>
      <UnderstandTheDifference
        visible={modals.moreInfo}
        onClose={() => setModalVisibility('moreInfo')(false)}
      />
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo 
        title='Storage HostDime'
        description='Solução de armazenamento escalável e segura, Em blocos SSD/NVMe, HDD ou objeto. Conheça o Cloud Storage HostDime.'
      />
      <PageTemplate
        title='Storage HostDime'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "storage/storage_hostdime@4x.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "contato/converse-com-nossos-especialistas.png" }) {
      childImageSharp {
        fluid(maxWidth: 264) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const enhance = compose(
  withSolutions
)

export default enhance(StaticPage)
